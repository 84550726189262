import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Stack,
  Box,
  CircularProgress,
  FormHelperText,
  Typography,
} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo/DemoContainer';
import moment from 'moment';
import { addMaintenance, fetchDeviceCountMonitor, getMonitorList } from '../../store/actions/areas';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { BarChart, BarPlot } from '@mui/x-charts/BarChart';
import { ChartsReferenceLine } from '@mui/x-charts/ChartsReferenceLine';
import { axisClasses } from '@mui/x-charts/ChartsAxis';


const MonitorModal = React.memo(({ open, onClose }) => {
    const dispatch = useDispatch();

    const [title, setTitle] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [dateRange, setDateRange] = useState({
        fromTimestamp: 0,
        toTimestamp:0,
    });
    const [data, setData] = useState([]);
    const [series, setSeries] = useState([]);
    const [referenceDeviceCount, setReferenceDeviceCount] = useState(0);

    useEffect(()=>{
        if(open.open)   {
            const dateStr = open.data.time + '.2025'
            const [day, month, year] = dateStr.split('.').map(Number);
            const date = new Date(year, month - 1, day);
            //new Date(Date.UTC(year, month - 1, day));
            const fromTimestamp = date.getTime() / 1000;
            const toTimestamp = fromTimestamp + 86400;

            setDateRange({
                fromTimestamp: fromTimestamp,
                toTimestamp: toTimestamp,
            })
            setTitle(open.data.time)
        }
    }, [open])

    useEffect(()=>{
        async function fetchDeviceCountMonitorRequest(area_id, fromTimestamp, toTimestamp) {
            setIsLoading(true)
            const result = await dispatch(fetchDeviceCountMonitor(area_id, fromTimestamp, toTimestamp))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setData(result.payload.data['data'])

            const modelNames = result.payload.data['models']
            let ser = Array()
            modelNames.map((title) => {
                ser.push({
                    dataKey: title,
                    label: title,
                    stack: 'A',
                    stackOffset: 'none',
                    stackOrder: 'ascending',
                    valueFormatter
                })
            })
            
            setSeries(ser)
            setReferenceDeviceCount(result.payload.data.reference_device_count)

            setIsLoading(false)
        }

        if(open.open)   {
            fetchDeviceCountMonitorRequest(open.area_id, dateRange.fromTimestamp, dateRange.toTimestamp)
        }
        
    }, [dateRange])

    const handleClose = () => {
        onClose(false);
    };

    const valueFormatter = (value) => `${value} шт.`;

    const chartSetting = {
        yAxis: [
            {
                id: 'yAxis',
                label: 'количество (шт.)',
            },
        ],
        series: series,
        height: 400,
        sx: {
            [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
                transform: 'translateX(-10px)',
            },
        }
    };

    return (
        <Dialog open={open.open} onClose={handleClose} fullWidth={true} maxWidth={false}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {
                    (isLoading)? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            height="100px" // Задайте высоту по вашему усмотрению
                            >
                            <CircularProgress />
                            <Typography variant="body1" style={{ marginTop: '16px' }}>
                                Загрузка данных
                            </Typography>
                        </Box>
                    ) : (
                        (data.length == 0) ? (
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                height="100px" // Задайте высоту по вашему усмотрению
                                >
                                    <Typography variant="body1" style={{ marginTop: '16px' }}>
                                        На данный момент нет данных для этой площадки
                                    </Typography>
                            </Box>
                        ) : (
                            <BarChart
                                dataset={data}
                                xAxis={[
                                    { scaleType: 'band', dataKey: 'time', tickPlacement:'middle', tickLabelPlacement:'middle' },
                                ]}
                                {...chartSetting}
                            >
                                {/* <BarPlot onItemClick={handleOpenMonitorModal} /> */}

                                {(referenceDeviceCount > 0) ? (
                                    <ChartsReferenceLine
                                        axisId="yAxis"
                                        y={referenceDeviceCount}  // Значение, на котором будет нарисована горизонтальная линия
                                        label={`Эталон (${referenceDeviceCount})`}
                                        labelAlign="end"
                                        lineStyle={{ stroke: 'red', strokeWidth: 2 }}
                                        labelStyle={{ fill: 'red', fontSize: 12 }}
                                    />
                                ): null}
                                
                            </BarChart>
                        )
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default MonitorModal;
