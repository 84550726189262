// @ts-nocheck
import { React, Component, useState, useEffect, createContext, useMemo, useRef, useCallback } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Oval } from  'react-loader-spinner'
import { batch } from 'react-redux';

import { Box, Button, ButtonGroup, Divider, Checkbox, Paper, Skeleton } from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ru } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, ResponsiveContainer } from 'recharts';
import { getDashboardModes, getDashboardSlicedData } from '../../store/actions/device';


export default function SlicedDashboard({dataProps}) {
    const dispatch = useDispatch();

    const [startDate, setStartDate] = useState(() => {
        const today = new Date();
        today.setDate(today.getDate() - 1);
        today.setHours(0, 0, 0, 0);
        return today;
    });
    const [endDate, setEndDate] = useState(() => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);
        return tomorrow;
    });
    const [selectedDataType, setSelectedDataType] = useState([]);
    const [expandedItems, setExpandedItems] = useState([]);
    const [chartDimensions, setChartDimensions] = useState({ width: 500, height: 800 });
    const chartContainerRef = useRef(null);
    const [selectedTimeframe, setSelectedTimeframe] = useState('1h');
    const [isLoading, setIsLoading] = useState(false);
    const [isModesLoading, setIsModesLoading] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [referenceChartData, setReferenceChartData] = useState([]);

    const _fetch_modes_AbortController = useRef(null)
    const _fetch_sliced_AbortController = useRef(null)

    const [treeData, setTreeData] = useState([])
    // const treeData = [
    //     {
    //         itemId: 'HASHRATE',
    //         label: 'Хешрейт (Th/s)',
    //         children: [
    //             { itemId: 'S_hashrate', label: 'Модели S', dataType: 'S_hashrate' },
    //             { itemId: 'L_hashrate', label: 'Модели L', dataType: 'L_hashrate' },
    //             { itemId: 'KS_hashrate', label: 'Модели KS', dataType: 'KS_hashrate' },
    //         ],
    //     },
    //     {
    //         itemId: 'DEVICE_COUNT',
    //         label: 'Кол-во устройств (шт.)',
    //         children: [
    //             { itemId: 'S_device_count', label: 'Модели S', dataType: 'S_device_count' },
    //             { itemId: 'L_device_count', label: 'Модели L', dataType: 'L_device_count' },
    //             { itemId: 'KS_device_count', label: 'Модели KS', dataType: 'KS_device_count' },
    //         ],
    //     },
    // ];

    const colorMap = {
        S_hashrate: '#4e79a7',
        L_hashrate: '#59a14f',
        KS_hashrate: '#9c755f',
        S_device_count: '#f28e2b',
        L_device_count: '#76b7b2',
        KS_device_count: '#edc948'
    };

    const generateColor = () => {
        const existingColors = Object.values(colorMap);
        
        const getColorDistance = (color1, color2) => {
            const r1 = parseInt(color1.slice(1, 3), 16);
            const g1 = parseInt(color1.slice(3, 5), 16);
            const b1 = parseInt(color1.slice(5, 7), 16);
            const r2 = parseInt(color2.slice(1, 3), 16);
            const g2 = parseInt(color2.slice(3, 5), 16);
            const b2 = parseInt(color2.slice(5, 7), 16);
            
            return Math.sqrt((r2-r1)**2 + (g2-g1)**2 + (b2-b1)**2);
        };

        let maxDistance = 0;
        let bestColor = null;

        for (let i = 0; i < 100; i++) {
            const r = Math.floor(Math.random() * 128);
            const g = Math.floor(Math.random() * (200 - 50 + 1)) + 50;
            const b = Math.floor(Math.random() * (255 - 100 + 1)) + 100;
            
            if (r < g && r < b) {
                const newColor = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
                const minDistance = Math.min(...existingColors.map(color => getColorDistance(newColor, color)));
                
                if (minDistance > maxDistance) {
                    maxDistance = minDistance;
                    bestColor = newColor;
                }
            }
        }

        return bestColor || '#000000';
    };

    function transformDataForRecharts(apiData) {
        // Функция для преобразования timestamp в локальное время
        function formatTimestamp(mtime) {
            const date = new Date(mtime * 1000); // Преобразуем UNIX timestamp в миллисекунды
            const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            };
            return date.toLocaleString('ru-RU', options).replace(',', '');
        }

        // Сгруппируем данные по уникальным ключам (key)
        const groupedData = {};
        apiData.sliced_data.forEach(({ key, value, mtime }) => {
            const formattedTime = formatTimestamp(mtime);
            if (!groupedData[key]) {
                groupedData[key] = [];
            }
            if(key.includes('foundry')) {
                groupedData[key].push({ time: formattedTime, value: (value / 1e4).toFixed(2) });
            } else if(key.includes('hashrate') || key.includes('/'))  {
                groupedData[key].push({ time: formattedTime, value: (value / 1e12).toFixed(2) });
            } else  {
                groupedData[key].push({ time: formattedTime, value: value });
            }
        });

        const keys = Object.keys(groupedData);

        // Формируем итоговый массив данных для Recharts
        const chartData = [];
        apiData.sliced_data.forEach(({ mtime }) => {
            const formattedTime = formatTimestamp(mtime);
            if (!chartData.some((item) => item.time === formattedTime)) {
            chartData.push({ time: formattedTime });
            }
        });

        keys.forEach((key) => {
            groupedData[key].forEach(({ time, value }) => {
            const dataPoint = chartData.find((item) => item.time === time);
                if (dataPoint) {
                    dataPoint[key] = value;
                }
            });
        });

        const _referenceData = apiData.area_data
        // apiData.area_data.forEach((value, index) => {
        //     Object.entries(value).forEach(([key, val]) => {
                
        //     });
        // })

        return { chartData, _referenceData, colorMap };
    }

    const transformApiResponse_Modes = (apiResponse) => {
        const treeData = [];

        const labelDictionary = {
            'HASHRATE': 'Хешрейт (Th/s)',
            'DEVICE_COUNT': 'Кол-во устройств (шт.)',
            'COUNTER': 'Счетчики (P)',
            'GGU': 'ГПУ',
            'S': 'Модели S',
            'L': 'Модели L',
            'KS': 'Модели KS',
            'DEVICE_TYPE_COUNT': 'Кол-во по типу',
            'POOL_HASHRATE': 'Пулы'
        };

        apiResponse.forEach(group => {
            const treeItem = {
                itemId: group.group,
                label: labelDictionary[group.group] || group.group,
                children: []
            };

            group.keys.forEach(key => {
                const prefix = key.split('_')[0];
                treeItem.children.push({
                    itemId: key,
                    label: labelDictionary[prefix] || key,
                    dataType: key
                });
            });

            treeData.push(treeItem);
        })

        return treeData;
    }

    const getAllNodeIds = (nodes) => {
        let ids = [];
        nodes.forEach(node => {
            ids.push(node.itemId);
            if (node.children) {
                ids = [...ids, ...getAllNodeIds(node.children)];
            }
        });
        return ids;
    };

    useEffect(() => {
        const fetchData = async (areaUuid) => {
            setIsModesLoading(true)

            console.log('Параметры изменились:');
            console.log('Дата начала:', startDate);
            console.log('Дата конца:', endDate);
            console.log('Выбранный таймфрейм:', selectedTimeframe);

            if(_fetch_modes_AbortController.current)  {
                _fetch_modes_AbortController.current.abort()
            }

            if(_fetch_sliced_AbortController.current)  {
                _fetch_sliced_AbortController.current.abort()
            }

            _fetch_modes_AbortController.current = new AbortController()

            const result = await dispatch(getDashboardModes(areaUuid, startDate, endDate, selectedTimeframe, _fetch_modes_AbortController.current))
            if(!result.success) {
                if(result.message !== 'cancel_request') {
                    return Swal.fire({text:result.message, icon: 'error'});
                }
            }

            const ret = transformApiResponse_Modes(result.payload.data)
            const allIds = getAllNodeIds(ret);

            batch(() => {
                setTreeData(ret)
                setExpandedItems(allIds);
            })

            setIsModesLoading(false)
        }

        const areaUuid = dataProps.areas[dataProps.areaIndex]?.id
        if(areaUuid != undefined)   {
            setChartData([]);
            setReferenceChartData([]);
            fetchData(areaUuid);
        }
    }, [startDate, endDate, selectedTimeframe, dataProps])


    useEffect(() => {
        const fetchData = async (areaUuid) => {
            setIsLoading(true);

            console.log('Параметры изменились:');
            console.log('Выбранные данные:', selectedDataType);
            console.log('Дата начала:', startDate);
            console.log('Дата конца:', endDate);
            console.log('Выбранный таймфрейм:', selectedTimeframe);

            if(_fetch_sliced_AbortController.current)  {
                _fetch_sliced_AbortController.current.abort()
            }

            _fetch_sliced_AbortController.current = new AbortController()

            const result = await dispatch(getDashboardSlicedData(areaUuid, selectedDataType, selectedTimeframe, startDate, endDate, _fetch_sliced_AbortController.current))
            if(!result.success) {
                if(result.message !== 'cancel_request') {
                    return Swal.fire({text:result.message, icon: 'error'});
                }
            }

            const { chartData, _referenceData, colorMap } = transformDataForRecharts(result.payload.data);

            batch(() => {
                setChartData(chartData);
                setReferenceChartData(_referenceData);
            });

            console.log('-----')
            console.log(chartData)
            console.log(_referenceData)
            console.log(colorMap)
            console.log('=======')

            setIsLoading(false);
        };

        const areaUuid = dataProps.areas[dataProps.areaIndex]?.id
        if(areaUuid != undefined)   {
            fetchData(areaUuid);
        }
    }, [selectedDataType]);

    const findNodeById = (nodes, id) => {
        for (let node of nodes) {
            if (node.itemId === id) {
                return node;
            }
            if (node.children) {
                const found = findNodeById(node.children, id);
                if (found) {
                return found;
                }
            }
        }
        return null;
    };

    const handleDataSelect = (event, nodeIds) => {
        const selectedNodes = nodeIds.map(id => findNodeById(treeData, id)).filter(node => node !== null);
        //const selectedDataTypes = selectedNodes.map(id => id.dataType)
        const selectedDataTypes = selectedNodes
            .filter(node => node.dataType !== undefined)
            .map(node => node.dataType);
        setSelectedDataType(selectedDataTypes)
        console.log('Выбранные узлы:', selectedDataTypes);
    };

    useEffect(() => {
        const updateChartDimensions = () => {
            if (chartContainerRef.current) {
                const { width } = chartContainerRef.current.getBoundingClientRect();
                setChartDimensions({ width: width, height: 500 }); // Фиксированная высота 300px
            }
        };

        updateChartDimensions(); // Вызов при монтировании
        window.addEventListener('resize', updateChartDimensions);

        return () => window.removeEventListener('resize', updateChartDimensions);
    }, []);

    const renderTree = (nodes) => (
        nodes.map((node) => (
            <TreeItem key={node.itemId} itemId={node.itemId} label={node.label}>
                {Array.isArray(node.children) ? renderTree(node.children) : null}
            </TreeItem>
        ))
    );

    const handleTimeframeChange = (timeframe) => {
        setSelectedTimeframe(timeframe);
        // Здесь вы можете добавить логику для обновления данных графика в соответствии с выбранным таймфреймом
    };

    const generateChartData = () => {
        const data = [];
        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            data.push({
                date: new Date(currentDate),
                value1: Math.random() * 100,
                value2: Math.random() * 50,
                value3: Math.random() * 200,
            });
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return data;
    };

    const DynamicLineChart = ({ data, referenceData, colorMap }) => {
        if(data.length == 0) return (
            <ResponsiveContainer width="100%" height={chartDimensions.height}>
                <LineChart 
                    width={chartDimensions.width}
                    height={chartDimensions.height}
                    data={[]}
                    margin={{ top: 5, right: 30, left: 10, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" angle={-45} textAnchor="end" height={80} style={{ fontSize: '12px' }}/>
                </LineChart>
            </ResponsiveContainer>
        )

        const keys = Object.keys(data[0]).filter(key => key !== 'time');
        // Генерируем отсутствующие цвета один раз перед рендерингом
        keys.forEach(key => {
            if (!colorMap[key]) {
                colorMap[key] = generateColor();
            }
        });

        const renderYAxis = (key, index) => (
            <YAxis
                key={key}
                yAxisId={key}
                orientation={index % 2 === 0 ? 'left' : 'right'}
                stroke={colorMap[key]}
                //label={{ value: key, angle: -90, position: 'insideLeft' }}
            />
        );

        const renderLines = () => keys.map(key => (
            <Line
                key={key}
                type="monotone"
                dataKey={key}
                stroke={colorMap[key]}
                yAxisId={key}
                dot={false}
            />
        ));

        const renderReferenceLines = () => {
            return referenceData.flatMap((value, index) => 
                Object.entries(value).map(([key, val]) => {
                    if(keys.includes(key))  {
                        return (<ReferenceLine 
                            key={`reference-${index}-${key}`}
                            y={val}
                            yAxisId={key}
                            stroke="red"
                            strokeDasharray="3 3"
                        />)
                    } else  {
                        return (<></>)
                    }
                })
            );
        }

        return (
            <ResponsiveContainer width="100%" height={chartDimensions.height}>
                <LineChart 
                    width={chartDimensions.width}
                    height={chartDimensions.height}
                    data={data}
                    margin={{ top: 5, right: 30, left: 10, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" angle={-45} textAnchor="end" height={80} style={{ fontSize: '12px' }}/>
                    {keys.map(renderYAxis)}
                    <Tooltip />
                    <Legend
                        layout="horizontal"
                        verticalAlign="top"
                        align="center"
                        wrapperStyle={{ paddingBottom: 10 }}
                    />
                    {renderLines()}
                    {renderReferenceLines()}

                </LineChart>
            </ResponsiveContainer>
        );
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Box sx={{ p: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
                    <Paper elevation={3} sx={{ 
                        p: 2, 
                        mb: 2, 
                        backgroundColor: '#f5f5f5',
                        borderRadius: 2,
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    }}>
                        <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2
                        }}>
                            <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center',
                            }}>
                                <DateTimePicker
                                    label="Дата начала"
                                    value={startDate}
                                    onChange={(newValue) => setStartDate(newValue)}
                                    views={['year', 'month', 'day', 'hours', 'minutes']}
                                    format="dd.MM.yyyy HH:mm"
                                    ampm={false}
                                    timeZone="Europe/Moscow"
                                    sx={{ 
                                        backgroundColor: 'white',
                                        borderRadius: 1,
                                        '& .MuiInputBase-root': { height: 56 }
                                    }}
                                />
                                <Box sx={{ width: '50px' }} />
                                <DateTimePicker
                                    label="Дата конца"
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                    views={['year', 'month', 'day', 'hours', 'minutes']}
                                    format="dd.MM.yyyy HH:mm"
                                    ampm={false}
                                    timeZone="Europe/Moscow"
                                    sx={{ 
                                        backgroundColor: 'white',
                                        borderRadius: 1,
                                        '& .MuiInputBase-root': { height: 56 }
                                    }}
                                />
                            </Box>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', my: 0 }}>
                                <Divider sx={{ width: '25%' }} />
                            </Box>
                            <ButtonGroup size="secondary" aria-label="timeframe selection">
                                <Button 
                                    onClick={() => handleTimeframeChange('5m')}
                                    variant={selectedTimeframe === '5m' ? 'contained' : 'outlined'}>
                                    5m
                                </Button>
                                <Button 
                                    onClick={() => handleTimeframeChange('30m')}
                                    variant={selectedTimeframe === '30m' ? 'contained' : 'outlined'}>
                                    30m
                                </Button>
                                <Button 
                                    onClick={() => handleTimeframeChange('1h')}
                                    variant={selectedTimeframe === '1h' ? 'contained' : 'outlined'}>
                                    1h
                                </Button>
                                <Button 
                                    onClick={() => handleTimeframeChange('1d')}
                                    variant={selectedTimeframe === '1d' ? 'contained' : 'outlined'}>
                                    1d
                                </Button>
                                <Button 
                                    onClick={() => handleTimeframeChange('1M')}
                                    variant={selectedTimeframe === '1M' ? 'contained' : 'outlined'}>
                                    1M
                                </Button>
                            </ButtonGroup>
                        </Box>
                    </Paper>
                </LocalizationProvider>
            </Box>
            <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
                <Box sx={{ width: 250, p: 2, overflowY: 'auto' }}>
                    {isModesLoading ? (
                        <Skeleton 
                            variant="rectangular" 
                            width={chartDimensions.width} 
                            height={chartDimensions.height} 
                            animation="wave"/>
                    ) : (
                        <SimpleTreeView
                            multiSelect
                            checkboxSelection
                            onSelectedItemsChange={handleDataSelect}
                            expandedItems={expandedItems}
                        >
                            {renderTree(treeData)}
                        </SimpleTreeView>
                    )}
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box sx={{ flexGrow: 1, p: 2, overflow: 'hidden' }} ref={chartContainerRef}>
                    {isLoading ? (
                        <Skeleton 
                            variant="rectangular" 
                            width={chartDimensions.width} 
                            height={chartDimensions.height} 
                            animation="wave"/>
                    ) : (
                        <DynamicLineChart data={chartData} referenceData={referenceChartData} colorMap={colorMap} />
                        // <LineChart
                            // width={chartDimensions.width}
                            // height={chartDimensions.height}
                            // data={chartData}
                            // margin={{ top: 5, right: 30, left: 10, bottom: 5 }}>
                        //         <CartesianGrid strokeDasharray="3 3" />
                        //         <Tooltip />
                        //         <XAxis dataKey="time" angle={-45} textAnchor="end" height={80} style={{ fontSize: '12px' }}/>
                        //         <YAxis yAxisId="left" style={{ fontSize: '12px'}}/>
                        //         <YAxis yAxisId="right" orientation="right" />
                        //         {/* <YAxis yAxisId="right" orientation="right" />
                        //         <YAxis yAxisId="right2" orientation="right" stroke="#ff84d8"/>
                        //         <YAxis yAxisId="extraRight" orientation="right" hide={true} domain={[0, 500]} /> */}
                        //         <Line yAxisId="left" type="monotone" dataKey="S_hashrate" stroke="#8884d8" />
                        //         <Line yAxisId="right" type="monotone" dataKey="KS_hashrate" stroke="#82ca9d" />
                        //         {/* <Line yAxisId="right" type="monotone" dataKey="value2" stroke="#82ca9d" />
                        //         <Line yAxisId="right2" type="monotone" dataKey="value3" stroke="#ffc658" />
                        //         <ReferenceLine y={350} yAxisId="extraRight" stroke="red" strokeDasharray="3 3" /> */}
                        //         <Legend
                        //             layout="horizontal"
                        //             verticalAlign="top"
                        //             align="center"
                        //             wrapperStyle={{ paddingBottom: 10 }}
                        //         />
                        // </LineChart>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

