import { React, Component, useState, useEffect, useRef, useMemo, memo, Fragment, useCallback } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, Section, Columns, Column } from 'react-bulma-components';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { Oval } from  'react-loader-spinner'
import Swal from 'sweetalert2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import { tooltipClasses } from '@mui/material/Tooltip';

import {addStatusPageEvent, getAreasList, getGGU, getGGUBeats, getMonitorBeats, getMonitors } from '../../store/actions';
import { Button, Skeleton, Typography, styled } from '@mui/material';
import EventDialog from './EventDialog';
import EventListDialog from './EventListDialog';
import MaintenanceList from './MaintenanceList';
import Monitors from './Monitors';
import { getIsAreasLoading } from '../../store/selectors';
import SlicedDashboard from './SlicedDashboard';

export default function StatusPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const counter_switch_list = [
        {title:'Тех. работы', is_active: false},
        {title:'Мониторы', is_active: true},
        {title:'Dashboard', is_active: false},
    ]

    const [areas, setAreas] = useState([]);
    const [selectedAreaIndex, setSelectedAreaIndex] = useState(0);
    const isLoading = useSelector(state => getIsAreasLoading(state));
    
    const [stageList, setStageList] = useState(counter_switch_list);
    const [stageIndex, setStageIndex] = useState(1);

    const [monitorProps, setMonitorProps] = useState({
        areas: [],
        areaIndex: 0
    });

    useEffect( () => { 
        async function fetchData() {
            const result = await dispatch(getAreasList())
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }
            setAreas(result.payload.data)

            setMonitorProps({
                areas: result.payload.data,
                areaIndex: 0
            })
        }
        fetchData();
    }, []);

    const onSwitchAreaIndex = async (index) => {
        setSelectedAreaIndex(index)

        setMonitorProps({
            areas: areas,
            areaIndex: index
        })
    }

    const onSwitchStage = async (index) => {
        setStageList([
            {title:'Тех. работы', is_active: (index == 0)},
            {title:'Мониторы', is_active: (index == 1)},
            {title:'Dashboard', is_active: (index == 2)},
        ])
        setStageIndex(index)
    }

    return (
        (isLoading) ? (
            <div className="hero is-fullheight is-flex">
                <div className="container has-text-centered mt-4"> 
                <Oval
                    height={80}
                    width={80}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                    />
                        <p className="mt-2">Загрузка</p>
                </div>
            </div>
        ):(
            <div>
                {(areas.length > 0)?(
                    <nav className="breadcrumb has-bullet-separator is-centered mt-5 mb-3" aria-label="breadcrumbs">
                        <ul>
                            {
                                areas.map((item, index) => {
                                    return (
                                        <li key={index} className={(selectedAreaIndex == index) ? "is-active": ""}>
                                            <a href="#" onClick={()=>{onSwitchAreaIndex(index)}}>{item.name}</a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </nav>):(
                        null
                    )
                }
                <nav className="breadcrumb has-bullet-separator is-centered mt-6" aria-label="breadcrumbs">
                    <ul>
                    {
                        stageList.map((item, index) => {
                            return (
                                <li key={index} className={(item.is_active) ? "is-active": ""}>
                                    <a href="#" onClick={()=>{onSwitchStage(index)}}>{item.title}</a>
                                </li>
                            )
                        })
                    }
                    </ul>
                </nav>
                
                <div className='mt-5'>
                    {
                        (stageIndex == 0) ? (
                            <MaintenanceList dataProps={monitorProps}/>
                        ) : (stageIndex == 1) ? (
                            <Monitors dataProps={monitorProps}/>
                        ) : (
                            <SlicedDashboard dataProps={monitorProps}/>
                        )
                    }
                </div>

            </div>
        )
    );
}


  