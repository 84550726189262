import HttpRequest from "./HttpRequest";
import axios from 'axios';

class DeviceProvider extends HttpRequest {

    constructor(token)   {
        super()
        if(token == null)   {
            // this.cancelTokenInstance = axios.CancelToken.source()
            this.abortController = new AbortController()
        } else  {
            //this.cancelTokenInstance = token
            this.abortController = token
        }
    }

    async fetchDeviceList(area_uuid)   {
        return await this.get(`/device/${area_uuid}`, {})
    }

    async fetchDevicePropertiesList(area_uuid)   {
        return await this.get(`/device/properties/${area_uuid}`, {}, {signal: this.abortController.signal})
    }

    async fetchWorkersPropertiesList(area_uuid)   {
        return await this.get(`/device/workers/${area_uuid}`, {}, {signal: this.abortController.signal})
    }

    async fetchBlockedDevicePropertiesList(area_uuid)   {
        return await this.get(`/device/hosts/blocked/${area_uuid}`, {}, {signal: this.abortController.signal})
    }

    async fetchTotalHashrateHistory(area_uuid, range)   {
        return await this.get(`/device/hashrate_history/${area_uuid}/${range}`, {})
    }

    async fetchTotalDeviceHistory(area_uuid, range)   {
        return await this.get(`/device/total_devices_history/${area_uuid}/${range}`, {})
    }

    async fetchDeviceHashrateHistoryByMac(area_uuid, mac, range)   {
        return await this.get(`/device/hashrate_history/${area_uuid}/${mac}/${range}`, {})
    }

    async rebootDevice(area_id, mac)   {
        const test = {
            area_id: area_id,
            macs: mac
        }
        console.log(test)
        return await this.post(`/device/reboot`, {
            area_id: area_id,
            macs: mac
        })
    }

    async pingDevice(area_id, mac)   {
        return await this.post(`/device/ping`, {
            area_id: area_id,
            macs: mac
        })
    }

    async changeBeingMode(area_id, mac, mode_type)   {
        return await this.post(`/device/being_mode`, {
            area_id: area_id,
            macs: mac
        }, {
            params: {
                mode_type: mode_type
            }
        })
    }

    async changeBeingModeForRoom(area_id, room_id, isSleep)   {
        return await this.post(`/device/being_mode_group`, {
            area_id: area_id,
            room_id: room_id
        }, {
            params: {
                is_sleep: isSleep
            }
        })
    }

    async taskInfo(task_id)   {
        return await this.post('/device/task_info/'+task_id)
    }

    async updateDeviceField(area_id, mac, field, data)   {
        return await this.put(`/device/properties`, {
            area_id: area_id,
            mac: mac,
            field: field,
            data: data
        })
    }

    // --------------------------------

    async createMiningDevice(device)   {
        return await this.post('/option', device)
    }

    async fetchMiningDeviceList()   {
        return await this.get(`/option`, {})
    }

    async deleteMiningDevice(id)   {
        return await this.delete('/option', {
            id
        })
    }

    async updateMiningDevice(device)   {
        console.log(device)
        return await this.put('/option', device)
    }

    // --------------------------------

    async fetchConsumptionList(area_id)   {
        return await this.get(`/consumption`, {area_id:area_id})
    }

    async deleteConsumption(id)   {
        return await this.delete('/consumption', {
            id
        })
    }

    async createConsumption(consumption)   {
        console.log(consumption)
        return await this.post('/consumption', consumption)
    }

    async updateConsumption(consumption)   {
        return await this.put('/consumption', consumption)
    }

    // --------------------------------

    async approveDeviceRequest(area_id, host_id, subnet_id)   {
        return await this.post('/device/hosts/approve', null, {
            params: {
                area_id: area_id,
                host_id: host_id,
                subnet_id: subnet_id
            }
        })
    }

    async rejectDeviceRequest(area_id, ip)   {
        return await this.post('/device/hosts/reject', null, {
            params: {
                area_id: area_id,
                ip: ip
            }
        })
    }

    async fetchAsicLogs(area_id, asic_ip, log_name)   {
        return await this.get(`/device/asic/log/${area_id}/${asic_ip}/${log_name}`, {})
    }

    async fetchDashboardSlicedData(area_uuid, dataTypes, sampling, startDate, endDate)   {
        return await this.post(`/statuspage/monitor/dashboard`, {
            area_id: area_uuid,
            data_types: dataTypes,
            sampling: sampling,
            start_date: startDate,
            end_date: endDate
        }, {
            signal: this.abortController.signal
        })
    }

    async fetchDashboardModes(area_uuid, startDate, endDate, sampling, )   {
        return await this.get(`/statuspage/monitor/dashboard/mode`, {
            area_id: area_uuid,
            start_date: startDate,
            end_date: endDate,
            sampling: sampling
        }, {
            signal: this.abortController.signal
        })
    }
}

export default DeviceProvider;